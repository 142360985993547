<template lang="pug">
  v-btn(
    color="primary"
    @click="candidatesSendInviteEmail"
    :disabled="disabled"
    outlined
    small
  ) Zoom invite email
</template>

<script>
import errorsMixin from '@/mixins/errors.mixin';
import apiCourses from '@/services/api/api-courses';
import courseService from '@/app/admin/models/courseService.js';

export default {
  mixins: [errorsMixin],

  props: {
    disabled: Boolean,
    courseID: Number,
    activeItems: Array,
  },

  data: () => ({
    actionsService: new courseService(apiCourses)
  }),

  methods: {
    async candidatesSendInviteEmail() {
      try {
        const candidatesIDS = this.activeItems.map(item => item.ID);
        let res = await this.actionsService.candidatesSendZoomInvite(this.courseID, candidatesIDS)
        this.$notify({text: 'Emails have been sent', type: 'success'})
      } catch (err) {
        this.processError(err)
      }
    }
  },
}
</script>
